import React, { useEffect, useState } from "react";

function AnimatedText({ text, delay }) {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let timeoutId;

        const animateWriting = () => {
            if (currentIndex < text.length) {
                //setIsTypingTrue();
                timeoutId = setTimeout(() => {
                    setCurrentText(prevText => prevText + text[currentIndex]);
                    setCurrentIndex(prevIndex => prevIndex + 1);
                    document.getElementsByClassName('conversation-window')[0].scrollTo(0, 10 * document.getElementsByClassName('conversation-window')[0].offsetHeight);
                }, delay);
            }
            
        };

        if (currentIndex < text.length) {
            animateWriting();
        }
        if (currentIndex >= text.length) {
            //setIsTypingFalse();
        }

        return () => {
            // Cleanup to clear the timeout if the component unmounts or re-renders.
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, [text, delay, currentIndex]);

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: currentText }} />
        </>
    );
}

export default AnimatedText;
