import "./App.css";
import React from "react";
import AnimatedText from "./components/AnimatedText";
import CopyChatModal from "./components/CopyChatModal";
import SpeechToText from './components/VoiceToText/SpeechToText'
import StreamApi from "./components/StreamApi";
import netowrk from "./assets/images/loader7.gif";
import arrow from "./assets/images/arrow2.png";
import exit from "./assets/images/exit.svg";
import refresh from "./assets/images/refresh.svg";
import logo from "./assets/images/logo.svg";
import brand from "./assets/images/middle_brand.svg";
import like from "./assets/images/like.svg";
import unlike from "./assets/images/unlike.svg";
import share from "./assets/images/share-icon.svg";
import share1 from "./assets/images/share-icon-1.svg";
import mic from "./assets/images/mic-icon.svg";
import copy from "./assets/images/copy.svg";
import loader from './assets/gif/loader-gif-10.gif'
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";

const baseURL = window.location.href

const devUrl =
  "https://9724skp0gf.execute-api.ap-southeast-1.amazonaws.com/develop";
const prodUrl =
  "https://ilvtdo56u3.execute-api.ap-southeast-1.amazonaws.com/production";
// const baseUrl = devUrl;
const baseUrl = process.env.REACT_APP_API_URL;
//const baseUrl = devUrl;
console.log("process.env.NODE_ENV: ", process.env.NODE_ENV);
console.log("Base url : " + baseUrl);
const defaultBusinessModel = "resox-bm-top-left";
const businessModelTopLeft = "resox-bm-top-left";
const businessModelBottomLeft = "resox-bm-bottom-left";
const businessModelTopRight = "resox-bm-top-right";
const businessModelBottomRight = "resox-bm-bottom-right";
localStorage.setItem("currentBusinessModel", defaultBusinessModel);

const sessionID =
  Date.now().toString(36) + Math.random().toString(36).substr(2);
console.log("sessionID ", sessionID);
localStorage.setItem("aida-resox", sessionID);
function App() {
  const [userMessages, setUserMessages] = React.useState([]);
  const [showChat, setShowChat] = React.useState(true);
  const [showSources, setShowSources] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [enalbeAsking, setEnableAsking] = React.useState(true);
  const [fetchingApi, setFetchingApi] = React.useState(false);
  let newRasWourcesApi = [];
  const [rawSources, setRawSource] = React.useState([]);
  const [prompts, setPrompts] = React.useState([
    {
      id: 1,
      heading:'Seeking Action ?',
      hover: 'Discuss your dispute with AIDA and we will connect you with the right mix of lawyers, mediators, arbitrators and conflict coaches',
      query:
        "I need information about professionals who can help me with my complex dispute.",
    },
    { id: 2,
      heading:'Need a listening ear?',
      hover: 'If you’re worried or anxious about what to do, have a confidential conversation with AIDA. AIDA is not able to provide legal advice, but in time, will be able to summarize relevant legal judgements. Check back for more updates',
      query: "I would like to talk about a situation I’m in" },
    {
      id: 3,
      heading:'Seeking Information?',
      hover: 'Discuss your dispute with AIDA and be introduced to cost-effective options',
      query:
        "I have a relatively small claim and would like to resolve the dispute amicably.",
    },
    {
      id: 4,
      heading:'Need a Negotiation Coach?',
      hover: 'AIDA is being trained as a negotiation coach. In time, you will be able to engage AIDA to think through your best negotiation strategy. Check back for more updates',
      query:
        "I need help to think through my dispute and how to negotiate a satisfactory outcome",
    },
  ]);
  const [showCopyModal,setShowCopyModal] = React.useState(false)
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [newMsg, setNewMsg] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [msgs, setMsgs] = React.useState([]);

  const [modalVersion,setModalVersion] = React.useState('normal')
  function handleCloseModal(){
    //setShowCopyModal(false)
    //const URL = 'https://dev.aida.resox.com/share/?threadId=' + threadId
    const URL = window.location.host + "/share/" + '?threadId=' + threadId
    console.log("full URL = ",URL)
    console.log("==>full URL = ",URL)
    console.log("()full URL = ",window.location.href)
    setModalVersion(prev=>'url copied')
    navigator.clipboard.writeText(URL);
    //navigate({pathname:"/share/",search:`?threadId=${threadId}`});
  }
  function changeModalVersion(version){
    setModalVersion(prev=>version)
  }
  function handleCloseModalOnly(){
    setShowCopyModal(false)
    changeModalVersion(prev=>'normal')
  }
  function copyChatUrl(){

  }
  React.useEffect(() => {
    if (loading) {
      //console.log("msgs: ",msgs.length)
      setMsgs((prev) => {
        return [...prev.slice(0, msgs.length - 1), newMsg];
      });
      if (
        userMessages.length > 0 &&
        userMessages[userMessages.length - 1].from === "app"
      ) {
        setUserMessages((prev) => {
          return [
            ...prev.slice(0, prev.length - 1),
            {
              id:
                Date.now().toString(36) + Math.random().toString(36).substr(2),
              from: "app",
              content: newMsg,
            },
          ];
        });
      } else {
        setUserMessages((prev) => {
          return [
            ...prev,
            {
              id:
                Date.now().toString(36) + Math.random().toString(36).substr(2),
              from: "app",
              content: newMsg,
            },
          ];
        });
      }
    }
    setTimeout(() => {
      document.getElementsByClassName("conversation-window")[0].scrollTop =
        document.getElementsByClassName("conversation-window")[0].scrollHeight;
    }, 100);

    //console.log('>',msgs)
  }, [newMsg]);

  React.useEffect(() => {
    newRasWourcesApi = rawSources.map((source) => {
      return (
        <a
          target="_blank"
          className="source-link"
          href={source.link}
          key={source.id}
        >
          <div className="sources">
            <div className="source-content">
              <img
                className="source-img"
                src={source.img}
                alt={source.header}
              />
              <div className="source-header-body">
                <h3 className="source-header">{source.header}</h3>
                <p className="source-body">
                  {source.body} {source.date}
                </p>
              </div>
            </div>
            <div className="horizontal-divider"></div>
          </div>
        </a>
      );
    });
  }, [rawSources]);
  // State to hold the value of the input
  const [inputValue, setInputValue] = React.useState('');

  // Event handler to update the state when the input changes
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const [threadId,setThreadId] = React.useState(null)
  const [link, setLink] = React.useState("");
  const [showLink, setShowLink] = React.useState(false);
  const [summary,setSummary] = React.useState(null)
  const [currentState,setCurrentState] = React.useState("before ask")
  const [displayedMessage,setDisplayedMessage] = React.useState('Discuss your dispute with AIDA and we will connect you with the right mix of lawyers, mediators, arbitrators and conflict coaches')
  const [displayedHeading,setDisplayedHeading] = React.useState('Seeking Action ?')
  const [selectDots,setSelectDots] = React.useState(true)
  const [secs,setSecs] = React.useState(0)
  const [queryType,setQueryType] = React.useState("message")
  const [showRetry,setShowRetry] = React.useState(false)
  React.useEffect(()=>{
    console.log("number of seconds = ",secs)
    if(secs < 10){
      console.log("normal response")
      //setSelectDots(prev=>true)
    }
    else{
      console.log("DRPs response")
      //setSelectDots(prev=>false)
    }
  },[secs])
  const showConversation = userMessages.map((msg) => {
    if (msg.from === "user") {
      return (
        <div className="user" key={msg.id}>
          <div className="yellow-circle"></div>
          <p className="msg-user">
            
            <div dangerouslySetInnerHTML={{ __html: msg.content.replace(/(\r\n|\r|\n)/g, "<br>") }} />
          </p>
        </div>
      );
    } else {
      return (
        <div className="app" key={msg.id}>
          <div className="green-square"></div>
          <div className="text-and-icons">
            <div className="msg-app">
              {msg.url && (
                <>
                <h1 style={{fontSize:'1.5rem',fontWeight:'bold'}}>We found ideal professionals to assist you!</h1>
                  <a
                    target="_blank"
                    className="btn btn-info bg-light py-3 px-5 my-3"
                    href={msg.content}
                  >
                    Click here
                  </a>
                  <p style={{fontSize:'0.9rem',fontWeight:'bold'}}>**Hand-pick your preferred professionals and confirm your email. They'll get in touch with you shortly.</p>
                </>
              )}
              {!msg.url && (
                <AnimatedText
                  //text={msg.content.replace(/(\r\n|\r|\n)/g, "<br>")}
                  text={msg.content.replace(/(\r\n|\r|\n)/g, "<br>")}
                  delay={5}
                />
              )}
            </div>

            {showSources && (
              <div className="like-icons mt-4">
                <img src={like} alt="like icon" />
                <img src={unlike} alt="dislike icon" />
              </div>
            )}
          </div>
        </div>
      );
    }
  });
  function resetAll() {
    setUserMessages([]);
    setShowChat(true);
    setShowSources(false);
    setError(null);
    setEnableAsking(true);
    const sessionID =
      Date.now().toString(36) + Math.random().toString(36).substr(2);
    localStorage.setItem("aida-resox", sessionID);
    localStorage.setItem("currentBusinessModel", defaultBusinessModel);

    setLink((prev) => '');
    setShowLink((prev) => false);
    setSummary(prev=>null)
  }
  /*  new resoX api  */
  async function getAIDA(text = "ok") {
    let finalSecs = 0
    const refreshIntervalId = setInterval(()=>{finalSecs+=0.5;setSecs(prev=>prev+0.5)}, 500);
    let numOfRetrys = 0
    let obj = {
      sessionInfo: {
        session: localStorage.getItem("aida-resox"),
      },
      type: localStorage.getItem("currentBusinessModel"),
      queryType:queryType,
      fulfillmentInfo: { tag: "NodeGpt" },
      text: text,
      languageCode: "en",
    };
    if(threadId){
      obj['threadId'] = threadId
    }
    setShowLink((prev) => false);
    setFetchingApi((prev) => true);
    setCurrentState(prev=>'asking questions no summary')
    setEnableAsking(prev=>false)
    setShowRetry(prev=>false)

    let res = await fetch(baseUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj), // body data type must match "Content-Type" header
    });
    let data = await res.json();

    while('message' in data){
      console.log("try ",numOfRetrys)
      if(numOfRetrys >= 3){
        setShowRetry(prev=>true)
        return
      }
      obj.queryType="retry"
      res = await fetch(baseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj), // body data type must match "Content-Type" header
      });
      data = await res.json();
      numOfRetrys++
    }
    
    clearInterval(refreshIntervalId);
    setSecs(prev=>0)
    console.log("finalSecs>",finalSecs)
    console.log('request = ',obj)
    console.log("AIDA = ", data);
    /*
    if('message' in data){
      console.log("time out...")
      handleNewMsg("app", 'server time out, Please try again.')
      setEnableAsking(prev=>true)
      return
    }
    */
    if(threadId!==undefined){
      setThreadId(prev=>{
        return data.fulfillmentResponse.messages[0].threadId || obj.threadId
      })
    }
    setSearchParams(prev=>{
      return {...prev,threadId:data.fulfillmentResponse.messages[0].threadId||threadId}
    })
    try{
      if ("text" in data.fulfillmentResponse.messages[0]) {
        const text = data.fulfillmentResponse.messages[0].text.text[0];
        let newText = ''
        const openingTag = text.indexOf('{')
        const closingTag = text.indexOf('}')
        if(openingTag >=0){
          if(openingTag===0){
            newText = text.substr(closingTag+1)
          }
          else{
            newText = text.substr(0,openingTag)
          }
          console.log('newText=',newText)
        }
        else{
          console.log("normal text")
          newText = text
        }
        handleNewMsg("app", newText);
        setShowLink((prev) => false);
        setEnableAsking(prev=>true)
      } else if ("payload" in data.fulfillmentResponse.messages[0]) {
        const url =
          data.fulfillmentResponse.messages[0].payload.richContent[0][0].anchor
            .href;
        const responseSummary = data.fulfillmentResponse.messages[0].payload.richContent[0][0].summary
        if(url==='Currently we cannot find anyone suitable for your dispute, please leave your name and phone to have a callback from us.'){
          setShowLink((prev) => false);
          handleNewMsg("app", url);
          return
        }
        setLink((prev) => url);
        setShowLink((prev) => true);
        console.log("time at url : ",secs)
        console.log("finalSecs at url : ",finalSecs)
        if(finalSecs < 10){
          handleNewMsg("app", url, false);
        }
        else{
          handleNewMsg("app", url, true);
        }
        setSummary(prev=>responseSummary)
        setCurrentState(prev=>'asking questions with summary')
        setEnableAsking(prev=>true)
        setSelectDots(prev=>true)
        setTimeout(() => {
          document.getElementsByClassName("conversation-window")[0].scrollTop =
            document.getElementsByClassName("conversation-window")[0].scrollHeight;
        }, 100)
      } else {
        console.log("could not find data...");
        //setEnableAsking(prev=>true)
      }
      //handle drps list when the queryType = 'drplist'
      if(data.fulfillmentResponse.messages[0].queryType==='drplist'){
        setSelectDots(prev=>false)
        setQueryType(prev=>'drplist')
        obj = {
          sessionInfo: {
            session: localStorage.getItem("aida-resox"),
          },
          type: localStorage.getItem("currentBusinessModel"),
          queryType:'drplist',
          fulfillmentInfo: { tag: "NodeGpt" },
          text: "",
          languageCode: "en",
        };
        if(threadId){
          obj['threadId'] = threadId
        }
        const res = await fetch(baseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj), // body data type must match "Content-Type" header
        });
        const data = await res.json();
        console.log("drplist:::",data)
        const linkData = data.fulfillmentResponse.messages[0].payload.richContent[0][0].anchor.href
        console.log("linkData = ",linkData)
        handleNewMsg("app", linkData, true);
        setFetchingApi((prev) => false);
      }
      //return queryType to message after handlig drplist
      if(queryType==='drplist'){
        setQueryType(prev=>'message')
      }
      //end the loading api state
      setFetchingApi((prev) => false);
    }
    catch(err){
      console.log("error with api: " ,err)
      setEnableAsking(prev=>true)
    }
  }
  React.useEffect(()=>{
    setSearchParams(prev=>{
      return {}
    })
  },[])
  /*  end of new resoX api  */
  React.useEffect(()=>{
    console.log("thread id = ",threadId)
  },[threadId])
  function toggleSources() {
    setShowSources((prev) => !prev);
  }
  function handleNewMsg(from, content, url = false) {
    const newMsgContent = {
      id: Date.now().toString(36) + Math.random().toString(36).substr(2),
      from: from,
      content: content,
      url: url,
    };
    console.log("... ", newMsgContent);
    setUserMessages((prev) => {
      return [...prev, newMsgContent];
    });
    document.getElementsByClassName("conversation-window")[0].scrollTop =
      document.getElementsByClassName("conversation-window")[0].scrollHeight;
  }
  async function handleNewMsgFromUser() {
    //const newusermsg = document.getElementsByClassName("chat-input")[0].value;
    const newusermsg = inputValue;
    //user did not enter anything yet
    if (newusermsg.trim() === '') { return }
    getAIDA(newusermsg);
    setInputValue('')
    handleNewMsg("user", newusermsg);
    setShowChat((prev) => false);
    if(userMessages.length > 3){
      setTimeout(() => {
        document.getElementsByClassName("conversation-window")[0].scrollTop =
          document.getElementsByClassName("conversation-window")[0].scrollHeight;
      }, 100);
    }
    document.getElementsByClassName("chat-input")[0].value = "";
    document.getElementsByClassName('chat-input')[0].style.height = '50px'
    document.getElementsByClassName('chat-input')[0].focus()
  }

  function handleEnterPressed(e) {
    if (e.key === "Enter" && !e.shiftKey) {
      console.log("inputValue = ",inputValue)
      
      if(enalbeAsking){console.log("enabled")}
      else{
        console.log("not enabled");
        //setInputValue(prev=>prev+'\n')
        return
      }
      handleNewMsgFromUser();
      setInputValue('')
      if(userMessages.length > 3){
        setTimeout(() => {
          document.getElementsByClassName("conversation-window")[0].scrollTop =
            document.getElementsByClassName(
              "conversation-window"
            )[0].scrollHeight;
        }, 100);
      }

    }
    /* to make input field grow with text */
    if(e.key === "Enter" && e.shiftKey){
      /*const oldHeight = document.getElementsByClassName('chat-input')[0].style.height
      console.log('(before)textare height = ',oldHeight)
      let newHeight = 40
      if(!oldHeight){
        newHeight = 80
      }
      else{
        newHeight = Number(oldHeight.split('px')[0]) + 20
      }
      document.getElementsByClassName('chat-input')[0].style.height = newHeight + 'px'
      console.log('(after)textare height = ',newHeight)*/
    }
  }
  React.useEffect(() => {
    document.addEventListener("click", async (e) => {
      if (e.target.classList.contains("option")) {
        widthChanged()
        setCurrentState(prev=>'asking questions no summary')
        console.log("to find the prompt id1");
        console.log("e: " + e);
        console.log("here");
        console.log("e.target: " + e.target.classList.toString());
        console.log(
          "e.target-option : " +
            e.target.classList.toString().includes("option-1")
        );
        var option1 = e.target.classList.toString().includes("option-1");
        var option2 = e.target.classList.toString().includes("option-2");
        var option3 = e.target.classList.toString().includes("option-3");
        var option4 = e.target.classList.toString().includes("option-4");

        if (option1 == true) {
          console.log("option 1");
          localStorage.setItem("currentBusinessModel", businessModelTopLeft);
        } else if (option2 == true) {
          console.log("option 2");
          localStorage.setItem("currentBusinessModel", businessModelTopRight);
        } else if (option3 == true) {
          console.log("option 3");
          localStorage.setItem("currentBusinessModel", businessModelBottomLeft);
        } else if (option4 == true) {
          console.log("option 4");
          localStorage.setItem(
            "currentBusinessModel",
            businessModelBottomRight
          );
        }

        // TODO get the prompt id and update the local storage for prompt model
        const content = e.target.textContent;
        handleNewMsg("user", content);
        setShowChat((prev) => false);

        getAIDA(content);
        //const newReply = await fetchData(content)
        //console.log("newReply = ", newReply)
        //handleNewMsg("app", newReply)
      }
    });
  }, []);
  React.useEffect(()=>{
    const allPrompts = document.getElementsByClassName("option")
    const summaryHeading = document.getElementsByClassName('summary-heading')[0]
    const summaryText = document.getElementsByClassName('summary-text')[0]
    for(let i=0;i<allPrompts.length;i++){
      const prompt = allPrompts[i]
      prompt.addEventListener("mouseover",()=>{
        setDisplayedMessage(prev=>prompts[i].hover)
        setDisplayedHeading(prev=>prompts[i].heading)
        summaryHeading.classList.remove('summary-hide')
        summaryText.classList.remove('summary-hide')
      })
      prompt.addEventListener("mouseleave",()=>{
        summaryHeading.classList.add('summary-hide')
        summaryText.classList.add('summary-hide')
      })
      prompt.addEventListener('click',()=>{
        //summaryHeading.style.visibility = 'visible'
        summaryHeading.classList.remove('summary-hide')
        summaryText.classList.remove('summary-hide')
        console.log("clicked prompt..")
      })
    }
  },[])
  React.useEffect(()=>{
    const textareaEl = document.getElementsByClassName('chat-input')[0]
    /* make input field grow with text when we paste any number of lines of text */
    textareaEl.addEventListener("paste",(e)=>{
      const clipboardData = e.clipboardData
      const pastedData = clipboardData.getData('Text');
      /* count number of \n (new line characters) in the pasted text */
      let numOfLines = pastedData.split(/\r\n|\r|\n/).length
      textareaEl.style.height = (numOfLines*30) + 'px'
    })
    /* make input field shrink when content is deleted */
    textareaEl.addEventListener('input',(e)=>{
      const content = e.target.value
      if(content.trim()===''){
        //console.log("empty...")
        textareaEl.style.height = '50px'
        return
      }
      let numOfLines = content.split(/\r\n|\r|\n/).length
      //console.log("num of lines = ",numOfLines)
      if(numOfLines===1){
        textareaEl.style.height = '50px'
        return
      }
      textareaEl.style.height = (numOfLines*30) + 'px'
      //console.log("changed... ",e.target.value)
    })
  },[])
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function widthChanged(){
    //console.log("currentState === ",currentState,window.innerWidth)
    if(currentState==='before ask' && window.innerWidth <= 1035){
      //console.log("hide summary section",currentState)
      document.getElementsByClassName('gif-section')[0].style.display ='none'
    }
    if(currentState==='before ask' && window.innerWidth > 1035){
      //console.log("show summary to the right side",currentState)
      document.getElementsByClassName('gif-section')[0].style.display ='inline'
    }
    if(currentState === 'asking questions no summary'){
      //console.log('show summary while asking',currentState)
      document.getElementsByClassName('gif-section')[0].style.display ='inline'
    }
  }
  React.useEffect(()=>{
    widthChanged()
  },[windowSize,currentState])
  const [text,setText]  = React.useState('')
  function setTextValue(val){
    setText(prev=>val)
    setInputValue(prev=>val)
  }
  React.useEffect(()=>{
    console.log("converted: ",text)
  },[text])
  return (
    <div className="App">
      <header className="App-header container-lg">
        <ul className="nav">
          <li className="brand">
            <a href="">
              <img src={logo} alt="logo" />
            </a>
          </li>
          <div className="btns">
            <li
              className="reset-btn"
              style={{ display: showChat ? "none" : "inline" }}
            >
              <a onClick={resetAll} href="">
              <img
                style={{ marginRight: "5px" }}
                src={refresh}
                alt="refresh button"
              />
              Go back
              </a>
              <div className="reset-border"></div>
            </li>
          </div>
        </ul>
      </header>
      <hr />
      <main className={`container-lg ${showChat ? "" : "show-chat-around"}`}>
        <section className="chat-section">
          <div
            style={{ display: showChat ? "inline" : "none" }}
            className="top-chat-area"
          >
            <div className="green-and-text">
              <div className="green-square"></div>
              <p className="chat-header">
                Hi, I am AIDA, a ResoX AI Dispute Advisor. How do I address you and
                how can I help you ?
              </p>
            </div>
            <div className="options"> 
              {prompts.map((q) => {
                  return (
                      <div className={`option option-${q.id}`} key={q.id}>
                        {q.query}
                      </div>
                  );
              })}
            </div>
          </div>

          <section
            style={{
              display: showChat ? "none" : "inline",
              overflowY: "scroll",
            }}
            className="conversation-window"
          >
            {showConversation}
            {fetchingApi && selectDots &&(
              <div className="app" key={0}>
                <div className="green-square"></div>
                <div className="msg-app">
                  <div id="animated-dots">
                    <div id="first-dot">.</div>
                    <div id="second-dot">.</div>
                    <div id="third-dot">.</div>
                  </div>
                </div>
              </div>
            )}
            {fetchingApi && !selectDots &&(
              <div className="app" key={0}>
                <div className="green-square"></div>
                <div className="msg-app">
                    Please wait while I search for ideal professionals
                </div>
              </div>
            )}
          </section>

          <div className="chat-box">
            <textarea 
              value={inputValue}
              onChange={handleInputChange}
              onKeyUp={handleEnterPressed}
              className={`chat-input`}
              type="text"
              placeholder="Enter a prompt..."
              style={{resize:'none'}}
            ></textarea>
            <button onClick={handleNewMsgFromUser} className="send-btn">
              {
                inputValue.length!==0 &&
                <img src={arrow} alt="arrow button" />
              }
            </button>
            <button disabled={threadId===null} onClick={()=>{setShowCopyModal(prev=>!prev)}}
             style={{border:'none',marginLeft:'10px',backgroundColor:'#0068A5',width:'77px'}}>
              <img width="26px" height="26px" style={{backgroundColor:'#0068A5',color:'white'}} src={share1} alt="" />
            </button>
          </div>

          {error && (
            <p className="error">Please check connection ({error.message})</p>
          )}
          {showRetry&&
          (<button onClick={()=>{getAIDA(text)}} className="btn btn-success">Retry</button>)
          }
          {/* footer was here */}
          <footer>
            <p>
              AIDA is an experimental tool in Beta and may be inaccurate at

              times. <a target="_blank" href="https://dev.resox.com/feedback-page/">Your feedback</a> will help us refine it.&nbsp;
              <a target="_blank" href="https://dev.resox.com/disclaimer/">Read Disclaimers.</a>

            </p>
          </footer>
        </section>

        <section className="gif-section container-lg">
        <div className={`gif-section-1 d-flex flex-column align-items-start gap-3 ${showSources ? "hide-gif-panel" : ""}`}>
          {/* after question is done AND a link is given */}
            {(showLink)
            &&
            <>
            <p className={`summary-heading `}>{displayedHeading}</p>
            <h1 className={`summary-text `}>{displayedMessage}</h1>
            <img className='loader-gif-img col-12' src={netowrk} alt='image here' />
            </>
            }
          </div>
          {/* before a question is asked */}
          {currentState==='before ask'&&
          <>
              {/* this element will only appear on mobile screen if there is not content to show */}
{/*
              {!displayedHeading&&<p className="summary-mobile">Dispute Summary:</p>}
          */}
            <p className="summary-heading summary-hide">{displayedHeading}</p>
            <h1 className="summary-text summary-hide">{displayedMessage}</h1>
            <img className='loader-gif-img col-12' src={netowrk} alt='image here' />
        </>
          }
          {/* after a question is asked */}

            
            {currentState==='asking questions no summary'&&
          <>
            <p className={`summary-heading ${currentState==='asking questions no summary' ?'' :'summary-hide'}`}>{displayedHeading}</p>
            <h1 className={`summary-text ${currentState==='asking questions no summary' ?'' :'summary-hide'}`}>{displayedMessage}</h1>
            <img className='loader-gif-img col-12' src={netowrk} alt='image here' />
          </>
          
          }
        </section>

        <CopyChatModal modalVersion={modalVersion} changeModalVersion={changeModalVersion} id={threadId} showModal={showCopyModal} closeModal={handleCloseModal} handleCloseModalOnly={handleCloseModalOnly} />
      </main>
    </div>
  );
}

export default App;
