import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import coppyIcon from '../assets/images/copy-icon.svg'
import verifyIcon from '../assets/images/verified-icon.svg'
import closeIcon from '../assets/images/close-icon.svg'
import copiedIcon from '../assets/images/copied-icon.svg'

function CopyChatModal({showModal,closeModal,id,handleCloseModalOnly,modalVersion,changeModalVersion}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showCopySuccess,setShowCopySuccess] = React.useState(false)
    function handleCloseModal(){
        closeModal()
        console.log("SP:",searchParams.threadId)
    }
    React.useEffect(()=>{
        if(modalVersion==='url copied'){
            setShowCopySuccess(prev=>true)
            setTimeout(()=>{
                setShowCopySuccess(prev=>false)
            },2000)
        }
    },[modalVersion])
    return (
        <section className="outer-copy-chat-modal" style={{display:showModal ? '':'none'}}>
            {/* url copied message */}
            {showCopySuccess
            &&
            <div className="verify-url-copied">
                <div className="copy-msg">
                    <p>Copy URL to Clipboard</p>
                    <img src={verifyIcon} alt="" /> 
                </div>
            </div>
            }
            {/* modal */}
            <div className="inner-copy-chat-modal">
                <div className="heading-section">
                    <h5 style={{marginRight:'auto',padding:'0 10px',fontWeight:'bold',color:'#0068A5'}} className="">Share Link to Chat</h5>
                    <button onClick={handleCloseModalOnly} className="close-btn">
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div className="horizontal-line"></div>
                <div className="situation-section">
                    <h5 style={{fontSize:'18px'}}>Dispute advisor recommendations</h5>
                    <p style={{color:'#959595',marginBottom:'0'}}>{new Date().toDateString()}</p>
                </div>
                <div className="horizontal-line"></div>
                <div className="buttons-section">
                    <button className="btn btn-primary" style={{backgroundColor:'#0068A5'}} onClick={handleCloseModal}>
                        <img src={modalVersion==='url copied'?copiedIcon:coppyIcon} style={{backgroundColor:'#0068A5',marginRight:'5px'}} alt="" />
                        {modalVersion==='url copied' ? 'copied':'Copy Link'}
                    </button>
                </div>
            </div>
        </section>
    );
}

export default CopyChatModal;
