import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import bot from '../Mask_Group_6 png_2.png'
import ReactTamil from './ReactTamil'

function ShareChat() {
    const reportTemplateRef = React.useRef(null);
    const saveHtmlAsPdf = () => {
        const contentElement = document.getElementById('content');
    
        html2pdf(contentElement, {
          margin: 10,
          filename: `Aida.Resox.com_${new Date().toISOString().split('T')[0]}(with_icons).pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        });
      };
    const handleGeneratePdf = () => {
        //saveHtmlAsPdf()
        document.getElementsByClassName('conversation-window-share')[0].classList.add('testPDFStyle')
        //const contentHeight = document.getElementById('content').offsetHeight
        //console.log("height = ",contentHeight)
        //const doc = new jsPDF("p", "px", [680, contentHeight])
		const doc = new jsPDF({
            orientation: 'portrait', // 'p'
            unit: 'px',
            autoSize: false
          });

		// Adding the fonts.
		doc.setFont('Inter-Regular', 'normal');

		doc.html(reportTemplateRef.current, {
			async callback(doc) {
				await doc.save(`Aida.Resox.com_${new Date().toISOString().split('T')[0]}.pdf`);
			},
            margin: [0, 0, 30, 0],
            autoPaging: 'text',
            x: 0,
            y: 0,
            width: 655, 
            windowWidth: 675
		});
        setTimeout(()=>{
            document.getElementsByClassName('conversation-window-share')[0].classList.remove('testPDFStyle')
        },1000)
	};
    const styles = {
		page: {
			marginLeft: '5rem',
			marginRight: '5rem',
			'page-break-after': 'always',
            fontSize:'0.5rem'
		},
	};
    //const param = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [threadId,setThreadId] = React.useState(null)
    const [date,setDate] = React.useState(null)
    const [userMessages, setUserMessages] = React.useState([
    ]);
    function exportToPdf() {
        console.log("exporting to pdf ...")
    }
    const showConversation = userMessages.map((msg) => {
        //console.log(`msg from (${msg.from}) : `,msg.content)
        if (msg.role === "user") {
            return (
                <div className="user user-2" key={msg.id ||msg._id}>
                    <div className="yellow-circle"></div>
                    <div className="msg-user">
                        
                        <div style={{textAlign:'left'}} dangerouslySetInnerHTML={{ __html: msg.content.replace(/(\r\n|\r|\n)/g, "<br>") }} />
                    </div>
                </div>
            );
        } else if(msg.role === "assistant") {
            return (
                <div className="app app-2" key={msg.id ||msg._id}>
                    <img className="bot-icon" width={'25px'} height={'25px'} src={bot} />
                    <div className="text-and-icons">
                        <div className="msg-app">
                            {msg.url && (
                                <>
                                    <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>We found ideal professionals to assist you!</h1>
                                    <a
                                        target="_blank"
                                        className="btn btn-info bg-light py-3 px-5 my-3"
                                        //href={link}
                                    >
                                        Click here
                                    </a>
                                    <p style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>**Hand-pick your preferred professionals and confirm your email. They'll get in touch with you shortly.</p>
                                </>
                            )}
                            {/*{msg.content}*/}
                            <div style={{textAlign:'left'}} dangerouslySetInnerHTML={{ __html: msg.content.replace(/(\r\n|\r|\n)/g, "<br>") }} />
                        </div>
                    </div>
                </div>
            );
        }
    });
    async function getChatHistory(threadIdStored){
        const res = await fetch('https://r3tkwvzwq9.execute-api.ap-southeast-1.amazonaws.com/production/chatHistory', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({threadId:threadIdStored}), // body data type must match "Content-Type" header
        });
        const data = await res.json()

        console.log("chat history = ",data)
        if(data.messages.length===0){
            const errMsg = {
                id: Date.now().toString(36) + Math.random().toString(36).substr(2),
                role: "assistant",
                content: "Could not find chat history, please try again later.",
                url: false,
              };
            console.log("new: ",errMsg)
            setUserMessages(prev=>{
                return [...prev,errMsg]
            })
            return
        }
        setDate(prev=>data.messages[0].createdAt.split('T')[0])
        setUserMessages(prev=>{
            return [...prev,...data.messages]
        })
    }
    useEffect(()=>{
        const myParam = searchParams.get('threadId');
        console.log("searchParams = ",myParam)
        getChatHistory(myParam)
    },[])
    return (
        <section className="share-page">
            <h1 className="share-heading">AIDA Find Professionals - Chat History.</h1>
            <div style={{width:'100%',display:'flex',justifyContent:'space-between',alignItems:'center',height:'100%'}}>
                <p style={{color:'rgba(172,172,190)',alignSelf:'center',margin:'0'}}> {date} </p>
                <button className="btn btn-outline-primary" onClick={()=>{window.print()}}> {/* onClick={handleGeneratePdf} */}
                    Generate PDF
                </button>
            </div>
            <div className="horizontal-line"></div>
            {/*<button className="btn btn-outline-primary" onClick={exportToPdf}>Export to PDF</button>*/}
            <section ref={reportTemplateRef} className="conversation-window-share " id="content">
                {showConversation}
            </section>
            {/*<ReactTamil msgs={userMessages} />
            <button className="btn btn-outline-primary" onClick={()=>{window.print()}}>print</button>*/}
        </section>
    );
}

export default ShareChat;
